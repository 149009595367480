import React from 'react';
import './Module.less';
import classnames from 'classnames';
import {BaseModuleProps} from '../include/Types';

type ContainerType = 'Default' | 'BoxDark' | 'BoxLight';

interface ModuleProps extends BaseModuleProps {
  container?: ContainerType;
  width?: 'Narrow' | 'Wide' | 'Unconstrained';
  layout?: 'Default' | 'Columns';
  titleColor?: 'Brand1' | 'Brand2';
  contentSize?: 'Small' | 'Medium' | 'Large';
  compact?: boolean;
}

const Module: React.FC<ModuleProps> = ({
  className,
  children,
  title,
  width,
  container: variantProp,
  layout,
  titleColor,
  contentSize,
  compact,
}) => {
  const variant: ContainerType = variantProp || 'Default';
  const content = (
    <div className={classnames('ModuleContent', width, `Body${contentSize || 'Large'}`)}>
      {title && <h2 className={titleColor}>{title}</h2>}
      {layout !== 'Default' ? <div className={layout}>{children}</div> : children}
    </div>
  );

  return (
    <div className={classnames('Module', {Compact: compact}, className)}>
      {variant === 'Default' ? (
        content
      ) : (
        <div className={classnames('Box', variant, width, {OnDark: variant === 'BoxDark'})}>{content}</div>
      )}
    </div>
  );
};

export default Module;
