import classNames from 'classnames';
import React, {ReactNode} from 'react';
import {BaseModuleProps} from '../include/Types';
import Module from './Module';
import './SimpleHeaderModule.less';

interface SimpleHeaderModuleProps extends Omit<BaseModuleProps, 'children'> {
  children?: ReactNode;
}

const SimpleHeaderModule: React.FC<SimpleHeaderModuleProps> = ({children, title, className}) => (
  <Module className={classNames('SimpleHeaderModule', className)}>
    <h1>{title}</h1>
    {children && <div className="BodyHuge">{children}</div>}
  </Module>
);

export default SimpleHeaderModule;
