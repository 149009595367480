import React from 'react';
import Layout from '../components/Layout';
import Module from '../components/Module';
import SimpleHeaderModule from '../components/SimpleHeaderModule';
import TeamBlurb from '../components/TeamBlurb';

export default () => (
  <Layout pageTitle="About Miter - Company, Team, and Values" description="Our team, our values, our mission.">
    <SimpleHeaderModule title="Miter is here to help teams work more effectively together." />

    <Module title="Our Team" layout="Columns" titleColor="Brand1">
      <div>
        <TeamBlurb
          name="Dave Feldman"
          avatarImageName="avatar-dave"
          title="CEO &amp; Founder"
          link="https://www.linkedin.com/in/davefeldman/"
          bio="Dave can’t resist collaboration tools: he’s held leadership roles on Gmail, Facebook Messenger, and Yahoo!
          Messenger and co-founded Emu, a smart messaging app acquired by Google. Most recently he served as VP Design
          at Heap. Dave holds a CS degree from Harvard and knows just enough React to be dangerous."
          worstMeeting="A calibration meeting in which someone said, “Our expectation is that the majority of employees
          exceed expectations.”"
        />
        <TeamBlurb
          name="Dr. Ashley Whillans"
          avatarImageName="avatar-ashley"
          title="Behavioral Science Advisor"
          link="https://www.linkedin.com/in/ashleywhillans/"
          bio={
            <>
              Dr. Whillans is an assistant professor at the Harvard Business school and teaches the “Negotiations” and
              “Motivation and Incentives” courses there. Her research focuses on the role of non-cash rewards on
              engagement and the links between time, money, and happiness. She is the author of
              <em>Time Smart: How to Reclaim Your Time &amp; Live a Happier Life.</em>
            </>
          }
          worstMeeting="A recurring company-wide meeting where execs made everyone sit through two-hour presentations relevant to only five people. Curiously, these meetings often coincided with “business trips” and “illnesses.”"
        />
      </div>
      <div>
        <TeamBlurb
          name="Damian Wisniewski"
          avatarImageName="avatar-damian"
          title="COO &amp; Founder"
          link="https://www.linkedin.com/in/damianwisniewski/"
          bio="As a seasoned go-to-market leader, Damian has built a career around meeting with people. An alumnus of LinkedIn, he’s built multiple sales practices from zero at early-stage startups including GoodHire, CleverTap and Got It. Damian holds a Mathematics degree from Harvard and an MBA from MIT Sloan."
          worstMeeting="The quarterly business review where execs gathered to see who the CEO would scream at, and bet on whether they'd be around next quarter."
        />
        <TeamBlurb
          name="Nicolas Gonzálvez"
          avatarImageName="avatar-nico"
          title="Founding Engineer"
          link="https://www.linkedin.com/in/ngonzalvez/"
          bio="Nico is a software engineer with ten years' professional experience in full-stack software development, specializing in Javascript, Node, and React. He holds a Computer Engineering degree from the University of the Republic, Uruguay."
        />
        <TeamBlurb
          name="Alex Frieder"
          avatarImageName="avatar-frieder"
          title="Technical Advisor"
          link="https://www.linkedin.com/in/afrieder/"
          bio="Alex is software engineer and Chief Internship Officer at Heap. To the extent Dave and Damian can credibly call themselves software engineers now, it's Alex's fault. When he and Dave worked together at Heap, Dave made him go to meetings but he didn't seem to mind too much."
        />
      </div>
    </Module>

    <Module container="BoxDark" layout="Columns" title="Our Mission">
      <div>
        We’ve been in a lot of bad meetings. We know you have, too—and we're committed to changing that. We want every
        team to be excited about accomplishing things together. It starts with meetings because done right, they're the
        conversations at the heart of accomplishing things. And, they're central to the relationships that make teams
        powerful.
      </div>
      <div>
        We founded Miter in fall of 2020. We were born <em>in </em>the pandemic but not <em>of</em> it: meetings were a
        problem long before everyone went remote. We raised our pre-seed funding in 2021 from
        <a href="https://macventurecapital.com/">MaC Venture Capital,</a>
        <a href="https://www.reachcapital.com/">Reach Capital,</a>
        <a href="https://www.newarkventurepartners.com/">Newark Venture Partners,</a> and angel investors.
      </div>
    </Module>

    <Module layout="Columns" title="Our Values" titleColor="Brand1">
      <div>
        <h3>Invest in People</h3>
        <p>Our most important asset is our team, and our strategic success and resilience depend on its strength.</p>
        <h3>Strength in Diversity</h3>
        <p>
          A diverse, inclusive team makes us strong—if we not only hire for it but empower it. That’s supported by data,
          and it’s axiomatic for us—self-evident.
        </p>
        <h3>Act Like You Run the Place</h3>
        <p>Your first responsibility is to the company. Keep that in mind, then take your autonomy and run with it.</p>
      </div>
      <div>
        <h3>Be Excellent to Each Other</h3>
        <p>How we treat each other, and how we treat our customers, defines who we are and how we succeed.</p>
        <h3>Deliver Something Meaningful</h3>
        <p>
          We're here to deliver real value to customers—specifically, to improve how teams collaborate. When we make
          trade-offs, we do so through the lens of that quest.
        </p>
      </div>
    </Module>

    <Module container="BoxLight" width="Narrow" title="Join Us!" titleColor="Brand1">
      We can’t do this alone. We’re looking for engineers who are scrappy, irreverent, and excited about workplace
      collaboration and the power of teams. Drop us a line: <a href="mailto:careers@miter.co">careers@miter.co</a>.
    </Module>
  </Layout>
);
