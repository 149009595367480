import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import React, {ReactNode} from 'react';
import './TeamBlurb.less';

interface TeamBlurbProps {
  avatarImageName: string;
  name: string;
  title: string;
  link: string;
  bio: ReactNode;
  worstMeeting?: ReactNode;
}

const TeamBlurb: React.FC<TeamBlurbProps> = ({avatarImageName, name, title, link, bio, worstMeeting}) => {
  const qResult = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        nodes {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          name
        }
      }
    }
  `);

  const imageData = getImage(qResult.allFile.nodes.find((node: Record<any, any>) => node.name === avatarImageName));

  return (
    <div className="TeamBlurb BodyLarge">
      <GatsbyImage className="Avatar" image={imageData} alt={`Picture of ${name}`} />
      <header>
        <a href={link}>{name}</a>
        <div>{title}</div>
      </header>
      <div className="Content">
        <p>{bio}</p>
        {worstMeeting && <p className="MeetingStory">Worst meeting: {worstMeeting}</p>}
      </div>
    </div>
  );
};

export default TeamBlurb;
