import React from 'react';
import {CTADestination} from '../include/Types';

interface CTAProps {
  dest: CTADestination;
  type?: 'Button' | 'Link';
  label: string;
}

const CTA: React.FC<CTAProps> = ({dest, type: propType, label}) => {
  const type = propType || 'Button';
  return (
    <a href={`/install?dest=${dest}`} target="_blank" className={`${type} CTA CTA_${dest}`}>
      {label}
    </a>
  );
};

export default CTA;
