import * as React from 'react';
import {Link} from 'gatsby';
import './Layout.less';
import {useState} from 'react';
import {useCallback} from 'react';
import classnames from 'classnames';
import Seo from './Seo';
import {LogoLockup, LogoMark} from './Logo';
import {MenuIcon} from './Icons';
import {useEffect} from 'react';
import {ReactNode} from 'react';
import CTA from './CTA';

interface LayoutProps {
  pageTitle?: string;
  description: string;
  children: ReactNode;
}

const NavItems: [string, string, boolean?][] = [
  ['Home', '/'],
  ['About', '/about'],
  ['Blog', 'https://blog.miter.co', true],
  ['Contact', '/contact'],
];

const Layout: React.FC<LayoutProps> = ({pageTitle, children, description}) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const toggleMenu = useCallback(() => {
    setMenuVisible(prevMenuVisible => !prevMenuVisible);
  }, []);

  useEffect(() => {
    //loadHeap();
  }, []);

  return (
    <div className="Layout">
      <Seo title={pageTitle} description={description} />
      <div className={classnames('NavScrim', {Toggled: isMenuVisible})} onClick={toggleMenu}></div>
      <header className={classnames({Toggled: isMenuVisible})}>
        <div className="Promo">
          Miter has shut down. <Link to="/">Learn More</Link>
        </div>
        <button
          className="NavButton"
          aria-label="menu"
          role="button"
          tabIndex={0}
          aria-controls="w-nav-overlay-0"
          aria-haspopup="menu"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <MenuIcon />
        </button>
        <nav>
          <Link to="/" className="Logo Item">
            <LogoLockup />
          </Link>
          {NavItems.map(([label, url, external]) => {
            return external ? (
              <a className="Item" key={url} href={url} onClick={toggleMenu}>
                {label}
              </a>
            ) : (
              <Link className="Item" key={url} to={url} onClick={toggleMenu}>
                {label}
              </Link>
            );
          })}
          <CTA dest="WebApp" label="Try Now" />
        </nav>
      </header>

      <main>{children}</main>

      <footer>
        <LogoMark />
        <div className="FooterItem">© 2021 Miter, Inc.</div>
        <a className="FooterItem" href="https://miter.co/privacy">
          Privacy
        </a>
        <a href="https://twitter.com/meetwithmiter" className="FooterItem">
          Twitter
        </a>
        <a className="FooterItem" href="https://www.linkedin.com/company/miterco/">
          LinkedIn
        </a>
      </footer>
    </div>
  );
};

export default Layout;
