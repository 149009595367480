/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * From https://github.com/gatsbyjs/gatsby/blob/master/starters/blog/src/components/seo.js
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

interface SeoProps {
  description: string;
  lang?: any;
  meta?: any;
  title?: string;
}

const Seo: React.FC<SeoProps> = ({description, lang, meta, title: suppliedTitle}) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              twitter
            }
            image
          }
        }
      }
    `
  );

  const metaDescription = description;
  const title = suppliedTitle
    ? `${suppliedTitle} - ${site.siteMetadata.title}`
    : `${site.siteMetadata.title}: ${metaDescription}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'og:image',
          content: site.siteMetadata?.image || ``,
        },
        {
          name: 'twitter:image',
          content: site.siteMetadata?.image || ``,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'google-site-verification',
          content: 'aLJsAxuRxNcQ7iLkPvSVpAGwK6LPNLUjpSrE77GpmAk',
        },
      ].concat(meta)}
    >
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_ID}`}></script>
      <script type="text/javascript">{`
        window.dataLayer = window.dataLayer || []; 
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date()); 
        gtag('config', '${process.env.GATSBY_GA_ID}');
        gtag('config', 'AW-10809421075');

      `}</script>
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default Seo;
