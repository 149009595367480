import React from 'react';

interface LogoProps {
  fillColor?: string;
  style?: React.CSSProperties;
}

export const LogoLockup: React.FC<LogoProps> = ({fillColor, style}) => (
  <svg style={style} className="LogoLockup" viewBox="0 0 95 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.87751 24.1704C7.50216 23.8478 5.15713 22.9811 3.33023 21.1542C-1.0795 16.7445 -1.11436 9.61922 3.25243 5.25243C7.61922 0.885639 14.7445 0.920501 19.1542 5.33023C20.948 7.124 21.8016 9.45625 22.1225 11.8296C17.5978 11.2148 12.9619 12.5729 11.5815 13.0281C11.3223 13.1183 11.12 13.3227 11.0324 13.5829C10.5898 14.9725 9.26628 19.6469 9.87751 24.1704ZM22.1225 11.8296C24.4978 12.1522 26.8429 13.0189 28.6698 14.8458C33.0795 19.2555 33.1144 26.3808 28.7476 30.7476C24.3808 35.1144 17.2555 35.0795 12.8458 30.6698C11.052 28.876 10.1982 26.5436 9.87751 24.1704C14.4022 24.7852 19.0381 23.4271 20.4185 22.9719C20.6777 22.8817 20.88 22.6773 20.9676 22.4171C21.4102 21.0275 22.7337 16.3531 22.1225 11.8296Z"
      fill={fillColor || '#33B8C1'}
    />
    <path
      d="M44.7143 20.6286C44.6191 20.4 44.4905 20.1095 44.3286 19.7571C44.1762 19.4048 44.0095 19.0238 43.8286 18.6143C43.6476 18.2048 43.4524 17.7857 43.2429 17.3571C43.0429 16.919 42.8524 16.5095 42.6714 16.1286C42.4905 15.7381 42.319 15.3905 42.1571 15.0857C42.0048 14.781 41.881 14.5476 41.7857 14.3857C41.681 15.5095 41.5952 16.7286 41.5286 18.0429C41.4619 19.3476 41.4048 20.6667 41.3571 22H40C40.0381 21.1429 40.081 20.281 40.1286 19.4143C40.1762 18.5381 40.2286 17.681 40.2857 16.8429C40.3524 15.9952 40.419 15.1714 40.4857 14.3714C40.5619 13.5714 40.6429 12.8143 40.7286 12.1H41.9429C42.2 12.519 42.4762 13.0143 42.7714 13.5857C43.0667 14.1571 43.3619 14.7571 43.6571 15.3857C43.9524 16.0048 44.2381 16.6286 44.5143 17.2571C44.7905 17.8762 45.0429 18.4429 45.2714 18.9571C45.5 18.4429 45.7524 17.8762 46.0286 17.2571C46.3048 16.6286 46.5905 16.0048 46.8857 15.3857C47.181 14.7571 47.4762 14.1571 47.7714 13.5857C48.0667 13.0143 48.3429 12.519 48.6 12.1H49.8143C50.1381 15.2905 50.381 18.5905 50.5429 22H49.1857C49.1381 20.6667 49.081 19.3476 49.0143 18.0429C48.9476 16.7286 48.8619 15.5095 48.7571 14.3857C48.6619 14.5476 48.5333 14.781 48.3714 15.0857C48.2191 15.3905 48.0524 15.7381 47.8714 16.1286C47.6905 16.5095 47.4952 16.919 47.2857 17.3571C47.0857 17.7857 46.8952 18.2048 46.7143 18.6143C46.5333 19.0238 46.3619 19.4048 46.2 19.7571C46.0476 20.1095 45.9238 20.4 45.8286 20.6286H44.7143Z"
      fill={fillColor || '#00313D'}
    />
    <path d="M56.2871 12.1H57.6728V22H56.2871V12.1Z" fill={fillColor || '#00313D'} />
    <path d="M70.3093 12.1V13.3H67.195V22H65.8093V13.3H62.695V12.1H70.3093Z" fill={fillColor || '#00313D'} />
    <path
      d="M75.344 22V12.1H81.3869V13.2857H76.7297V16.2286H80.8726V17.3857H76.7297V20.8143H81.744V22H75.344Z"
      fill={fillColor || '#00313D'}
    />
    <path
      d="M91.9196 17.8571C92.0719 18.0476 92.2624 18.3 92.491 18.6143C92.7291 18.919 92.972 19.2619 93.2196 19.6429C93.4672 20.0143 93.71 20.4048 93.9481 20.8143C94.1958 21.2238 94.4053 21.619 94.5767 22H93.0624C92.8719 21.6381 92.6624 21.2714 92.4339 20.9C92.2148 20.5286 91.991 20.1762 91.7624 19.8429C91.5339 19.5 91.3053 19.181 91.0767 18.8857C90.8577 18.5905 90.6577 18.3333 90.4767 18.1143C90.3529 18.1238 90.2243 18.1286 90.091 18.1286C89.9672 18.1286 89.8386 18.1286 89.7053 18.1286H88.4624V22H87.0767V12.2429C87.4672 12.1476 87.9005 12.0857 88.3767 12.0571C88.8624 12.019 89.3053 12 89.7053 12C91.0958 12 92.1529 12.2619 92.8767 12.7857C93.61 13.3095 93.9767 14.0905 93.9767 15.1286C93.9767 15.7857 93.8005 16.3476 93.4481 16.8143C93.1053 17.281 92.5958 17.6286 91.9196 17.8571ZM89.8196 13.2143C89.2291 13.2143 88.7767 13.2286 88.4624 13.2571V16.9857H89.4481C89.9243 16.9857 90.3529 16.9619 90.7339 16.9143C91.1148 16.8667 91.4339 16.7762 91.691 16.6429C91.9577 16.5095 92.1624 16.3238 92.3053 16.0857C92.4481 15.8381 92.5196 15.5143 92.5196 15.1143C92.5196 14.7429 92.4481 14.4333 92.3053 14.1857C92.1624 13.9381 91.9672 13.7429 91.7196 13.6C91.4815 13.4571 91.1958 13.3571 90.8624 13.3C90.5386 13.2429 90.191 13.2143 89.8196 13.2143Z"
      fill={fillColor || '#00313D'}
    />
  </svg>
);

export const LogoMark: React.FC = () => (
  <svg className="LogoMark" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.4527 162.36C80.1174 160.549 66.9523 155.684 56.6961 145.427C31.9397 120.671 31.7439 80.6693 56.2592 56.154C80.7746 31.6387 120.776 31.8344 145.533 56.5908C155.603 66.661 160.395 79.7544 162.196 93.0783C136.794 89.6271 110.769 97.2515 103.019 99.8066C101.564 100.313 100.428 101.461 99.9363 102.921C97.4517 110.723 90.0212 136.965 93.4527 162.36ZM162.196 93.0783C175.532 94.8896 188.697 99.7551 198.953 110.011C223.709 134.768 223.905 174.769 199.39 199.285C174.875 223.8 134.873 223.604 110.117 198.848C100.046 188.778 95.2533 175.684 93.4527 162.36C118.855 165.811 144.88 158.187 152.63 155.632C154.085 155.125 155.221 153.978 155.713 152.517C158.197 144.716 165.628 118.474 162.196 93.0783Z"
      fill="#32C9C7"
    />
  </svg>
);
